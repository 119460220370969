<script>
export default {
	name: 'AgreementPdf',
	props: {
		agreement: {
			type: Object
		}
	},
	methods: {
		toggleAgreedTo: function () {
			this.$emit('checkbox-clicked', this.agreement.id, !this.agreement.agreed)
		}
	},
	computed: {
		icon: function () {
			return this.agreement.agreed ? ['fas', 'check-square'] : ['far', 'square']
		},
		showCheckboxRow: function () {
			return this.agreement.icon.length !== 0
		}
	}
}
</script>
<template>
	<div class="agreement-pdf">
		<div class="agreement-pdf__content">
			<font-awesome-icon class="agreement-pdf__icon" :icon="['far', 'file-pdf']"/>
			<p class="agreement-pdf__link">
				<a :href="agreement.content" target="_blank">{{ agreement.header }}</a>
			</p>
		</div>
		<div class="agreement-pdf__checkbox-row" v-if="showCheckboxRow">
			<p class="agreement-pdf__checkbox-group" @click="toggleAgreedTo" data-testid="agreement-pdf-checkbox">
				<span>{{ $t('agreement.readAndAccept') }}</span>
				<font-awesome-icon class="agreement-pdf__checkbox-icon" :icon="icon"/>
			</p>
		</div>
	</div>
</template>
<style lang="scss">
.agreement-pdf {
	align-items: center;
	border: 1px solid var(--border-colour);
	border-radius: var(--border-radius);
	flex-direction: column;
	display: flex;
	margin: 0.5rem 0;
	width: 100%;
    &__content {
		align-items: center;
		display: flex;
		padding: 0.85rem;
		width: 100%;
	}
	&__icon {
		font-size: 1.6rem;
		margin-right: 1rem;
		min-width: 1rem;
		min-height: 1rem;
	}
	&__link {
		a {
			color: var(--link-colour);
			cursor: pointer;
			filter: brightness(80%);
			font-size: var(--font-size-body-2);
			font-weight: var(--font-weight-body-2);
			letter-spacing: 0.15px;
		}
	}
	&__checkbox {
		&-row {
			background-color:  #f9f9f9;
			border-top: 1px solid var(--border-colour);
			border-radius: 0 0 var(--border-radius) var(--border-radius);
			padding: 0.85rem;
			width: 100%;
		}
		&-group {
			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 0.9rem;
			justify-content: space-between;
		}
		&-icon {
			font-size: var(--font-size-subheading);
			margin-left: auto;
			margin-right: 0.16rem;
		}
	}
}
</style>
