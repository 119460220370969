<script>
import AgreementPdf from '@/components/shared/agreements/AgreementPdf.vue'
import BaseAccordion from 'lowfoot-components/BaseAccordion'
import AgreementService from '@/services/AgreementService'
import BrandingService from '@/services/BrandingService'

export default {
	name: 'Agreement',
	components: { AgreementPdf, BaseAccordion },
	props: {
		location: {
			type: String
		}
	},
	data: () => ({
		agreements: [],
		brandingAgreement: {}
	}),
	created: function () {
		this.brandingAgreement = BrandingService.get().getAgreement()
		for (let agreement of this.apiAgreements) {
			agreement = agreement.$toJson()
			if (agreement.type === 'HTML') {
				this.agreements.push({
					...agreement,
					heading: agreement.header,
					content: agreement.content,
					defaultState: 'COLLAPSE',
					scrollable: false,
					icon: this.agreementIcon,
					scrolledToEnd: (agreement.scrollToEnd === false),
					agreed: false })
			} else {
				this.agreements.push({ ...agreement, agreed: false, icon: this.agreementIcon })
			}
		}
		if (this.agreements.length < 1) {
			this.$emit('no-agreements')
		} else {
			if (this.htmlAgreements.length > 0) this.htmlAgreements[0].defaultState = 'EXPAND'
			this.$emit('all-agreed', this.allAgreed)
		}
	},
	methods: {
		toggleMasterAgreed: function () {
			const allAgreedState = !this.allAgreed
			this.agreements.forEach(agreement => {
				agreement.agreed = allAgreedState
			})
			this._toggleAccordionIcon(allAgreedState)
			this.$emit('all-agreed', this.allAgreed)
		},
		updateAgreementState: function (agreementId, agreementState) {
			const agreement = this.agreements.find((a) => a.id === agreementId)
			agreement.agreed = agreementState
			this.$emit('all-agreed', this.allAgreed)
		},
		updateAccordionAgreementState: function (agreementId) {
			const agreement = this.agreements.find((a) => a.id === agreementId)
			agreement.icon = (agreement.icon[1] === 'square') ? ['fas', 'check-square'] : ['far', 'square']
			agreement.agreed = (agreement.icon[1] === 'check-square')
			this.$emit('all-agreed', this.allAgreed)
		},
		_toggleAccordionIcon: function (allAgreedState) {
			if (this.showSingleCheckbox && this.showMultipleCheckboxes) {
				this.htmlAgreements.forEach(agreement => {
					agreement.icon = allAgreedState ? ['fas', 'check-square'] : ['far', 'square']
				})
			}
		},
		handleScrollToEnd: function (agreementId) {
			const agreement = this.agreements.find((a) => a.id === Number(agreementId))
			agreement.scrolledToEnd = true
		}
	},
	computed: {
		apiAgreements: function () {
			return AgreementService.findByLocation(this.location)
		},
		allAgreed: function () {
			return this.agreements.reduce((allAgreed, a) => {
				return allAgreed && a.agreed
			}, true)
		},
		allHTMLScrolled: function () {
			return this.htmlAgreements.reduce((allScrolled, a) => {
				return (allScrolled && a.scrolledToEnd)
			}, true)
		},
		icon: function () {
			return this.allAgreed ? ['fas', 'check-square'] : ['far', 'square']
		},
		pdfAgreements: function () {
			return this.agreements.filter(a => a.type === 'PDF')
		},
		htmlAgreements: function () {
			return this.agreements.filter(a => a.type === 'HTML')
		},
		showAgreementAccordion: function () {
			return this.htmlAgreements && this.htmlAgreements.length > 0
		},
		showSingleCheckbox: function () {
			const multipleAgreement = this.brandingAgreement.multipleAgreementCheckbox
			return (multipleAgreement === 'SINGLE' || multipleAgreement === 'BOTH')
		},
		showMultipleCheckboxes: function () {
			const multipleAgreement = this.brandingAgreement.multipleAgreementCheckbox
			return (multipleAgreement === 'MULTIPLE' || multipleAgreement === 'BOTH')
		},
		agreementIcon: function () {
			return this.showMultipleCheckboxes ? ['far', 'square'] : []
		}
	}
}
</script>
<template>
	<div class="agreement">
		<div class="agreement__pdf-group">
			<div v-for="agreement of pdfAgreements" :key="agreement.id">
				<agreement-pdf :agreement="agreement" ref="agreementPdf" data-testid="agreement-pdf-component"
					@checkbox-clicked="updateAgreementState" />
			</div>
		</div>
		<base-accordion v-if="showAgreementAccordion"
						data-testid="agreement-accordion"
						modifiers="border"
						class="agreement__accordion"
						:tab-options="htmlAgreements"
						@icon-clicked="updateAccordionAgreementState"
						@scroll-to-end="handleScrollToEnd"
						@tab-not-scrollable="handleScrollToEnd">
			<div class="agreement__accordion-content" slot-scope="{ tab }" slot="tab" :data-tab-id="tab.id">
				<div v-html="tab.content"/>
			</div>
		</base-accordion>
		<div class="agreement__controls" v-show="showSingleCheckbox">
			<p class="agreement__checkbox" @click="toggleMasterAgreed"
				:class="{ 'agreement__checkbox--disabled' : !allHTMLScrolled }"
				:key="allHTMLScrolled">
				<font-awesome-icon class="agreement__checkbox-icon" :icon="icon" data-testid="single-agreement-checkbox" />
				<span>{{ $t('agreement.acceptAll')}}</span>
			</p>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.agreement {
	display: flex;
    flex-direction: column;
	padding: 0.5rem;
	width: 100%;
	&__pdf-group {
		display: flex;
		flex-direction: column;
		margin-bottom: 0.5rem;
	}
	&__accordion {
		border-radius: var(--border-radius);
		overflow: hidden;
		::v-deep .base-card__header {
			background-color: var(--background-variant-light);
			color: var(--on-background);
			padding: 0.35rem 0;
		}
		::v-deep .base-card__icon {
			font-size: 1.15rem;
			color: var(--on-surface);
		}
		::v-deep .base-accordion__tab--header {
			padding: 0.5rem 1rem;
		}
		::v-deep .base-accordion__tab--active .base-card__header {
			background-color: #f9f9f9;
		}
		&-content {
			font-size: 0.7rem;
			font-weight: var(--font-weight-overline);
			height: 20vh;
			overflow: auto;
			padding: 0.5rem;
			word-break: break-all;
			::v-deep *:not(th):not(td):not(img) {
				width: 100% !important;
			}
		}
	}
	&__controls {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		margin-top: 1rem;
	}
	&__checkbox {
		align-items: center;
		cursor: pointer;
		display: flex;
		&-icon {
			font-size: 1.15rem;
			margin-right: 0.5rem;
		}
		&--disabled {
			color: #bbb;
			cursor: not-allowed;
			pointer-events: none;
		}
	}
}

</style>
